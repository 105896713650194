export const rolesNavigation = {
    owner: {
        id: "actions",
        title: "Actions",
        translate: "NAV.ACTIONS",
        type: "group",
        children: [
            {
                id: "dashboard",
                title: "Dashboard",
                translate: "NAV.DASHBOARD.TITLE",
                type: "item",
                icon: "ballot",
                url: "/owner/dashboard",
            },
            {
                id: "guests",
                title: "Guests",
                translate: "NAV.GUESTS.TITLE",
                type: "item",
                icon: "people_outline",
                url: "/owner/invitations/guests",
            },
            {
                id: "invitations",
                title: "Events",
                translate: "NAV.INVITATIONS.TITLE",
                type: "item",
                icon: "email",
                url: "/owner/invitations/list",
            },
            {
                id: "econtacts",
                title: "Emergency Contacts",
                translate: "NAV.ECONTACTS.TITLE",
                type: "item",
                icon: "contacts",
                url: "/owner/emergency-contacts",
            },
            {
                id: "instructions",
                title: "Instructions",
                translate: "NAV.INSTRUCTIONS.TITLE",
                type: "item",
                icon: "list",
                url: "/owner/instructions",
            },
            {
                id: "vehicles",
                title: "Vehicles",
                translate: "NAV.VEHICLES.TITLE",
                type: "item",
                icon: "directions_car",
                url: "/owner/vehicles",
            },
            {
                id: "pets",
                title: "Pets",
                translate: "NAV.PETS.TITLE",
                type: "item",
                icon: "pets",
                url: "/owner/pets",
            },
            // {
            //     id       : 'send',
            //     title    : 'Send Invitation',
            //     translate: 'NAV.INVITE.TITLE',
            //     type     : 'item',
            //     icon     : 'email',
            //     url      : '/invitations/invite'
            // },
            // {
            //     id       : 'scan',
            //     title    : 'Visitor Log',
            //     translate: 'NAV.SCANS.TITLE',
            //     type     : 'item',
            //     icon     : 'open_in_browser',
            //     url      : '/owner/reports/access'
            // },
            {
                id: "staff",
                title: "Staff",
                translate: "NAV.STAFF.TITLE",
                type: "item",
                icon: "person",
                url: "/owner/staff",
            },
            {
                id: "owner_reports",
                title: "Reports",
                translate: "NAV.REPORTS.TITLE",
                type: "collapsable",
                icon: "format_indent_increase",
                children: [
                    {
                        id: "owner_access",
                        title: "Visitor Logs",
                        translate: "NAV.REPORTS.ACCESS.TITLE",
                        type: "item",
                        icon: "open_in_browser",
                        url: "/owner/reports/access",
                    },
                ],
            },
        ],
    },
    security: {
        id: "security",
        title: "Security",
        translate: "NAV.SECURITY",
        type: "group",
        icon: "settings",
        children: [
            {
                id: "control",
                title: "Control",
                translate: "NAV.CONTROL.TITLE",
                type: "item",
                icon: "fullscreen",
                url: "/security/control",
            },
            {
                id: "today",
                title: "Today Guests",
                translate: "NAV.TODAY.TITLE",
                type: "item",
                icon: "people_outline",
                url: "/security/today",
            },
            {
                id: "doors",
                title: "Doors",
                translate: "NAV.DOORS.TITLE",
                type: "item",
                icon: "business",
                url: "/security/doors",
            },
            {
                id: "security_monitor",
                title: "Live Access Monitor",
                translate: "NAV.MONITOR.TITLE",
                type: "item",
                icon: "web_asset",
                url: "/security/live-access-monitor",
            },
            {
                id: "events",
                title: "Events",
                translate: "NAV.EVENTS.TITLE",
                type: "item",
                icon: "event",
                url: "/security/events",
                badge: {
                    title: "0",
                    bg: "#5490F0",
                    fg: "#FFFFFF",
                },
            },
            {
                id: "security_reports",
                title: "Reports",
                translate: "NAV.REPORTS.TITLE",
                type: "collapsable",
                icon: "format_indent_increase",
                children: [
                    {
                        id: "security_report_access_mon",
                        title: "Access Monitoring",
                        translate: "NAV.REPORTS.ACCESS_MON.TITLE",
                        type: "item",
                        icon: "exit_to_app",
                        url: "/security/reports/access-monitoring",
                    },
                    {
                        id: "security_access",
                        title: "Visitor Logs",
                        translate: "NAV.REPORTS.ACCESS.TITLE",
                        type: "item",
                        icon: "open_in_browser",
                        url: "/security/reports/access",
                    },
                    {
                        id: "report",
                        title: "Vehicles",
                        translate: "NAV.VREPORT.TITLE",
                        type: "item",
                        icon: "directions_car",
                        url: "/security/reports/vehicle",
                    },
                    {
                        id: "vreportnew",
                        title: "Vehicle Details",
                        translate: "NAV.VREPORTNEW.TITLE",
                        type: "item",
                        icon: "directions_car",
                        url: "/security/reports/vehicle_new",
                    },
                    {
                        id: "incident_report",
                        title: "Incident Report",
                        translate: "NAV.REPORTS.INCIDENT_REPORT.TITLE",
                        type: "item",
                        icon: "directions_car",
                        url: "/security/reports/incident-report",
                    },
                ],
            } /*,
            {
                'id'      : 'license-scan',
                'title'   : 'Scan License',
                'translate': 'NAV.LICENSE_SCAN.TITLE',
                'type'    : 'item',
                'icon'    : 'featured_video',
                'url'      : '/security/license-scan'
            }*/,
        ],
    },
    buildingAdmin: {
        id: "buildingAdmin",
        title: "Site Admin",
        translate: "NAV.BADMIN",
        type: "group",
        icon: "settings",
        children: [
            {
                id: "info",
                title: "Site Information",
                translate: "NAV.BUILDINGINFO.TITLE",
                type: "item",
                icon: "business",
                url: "/building/information",
            },
            {
                id: "staff",
                title: "Site Staff",
                translate: "NAV.STAFF.TITLE",
                type: "item",
                icon: "business",
                url: "/building/staff",
            },
            {
                id: "owners",
                title: "Units",
                translate: "NAV.OWNERS.TITLE",
                type: "item",
                icon: "people_outline",
                url: "/building/owners",
            },
            {
                id: "departments",
                title: "Departments",
                translate: "NAV.DEPARTMENTS.TITLE",
                type: "item",
                icon: "people_outline",
                url: "/building/departments",
                hidden: true,
            },
            {
                id: "doors",
                title: "Doors",
                translate: "NAV.DOORS.TITLE",
                type: "item",
                icon: "view_week",
                url: "/building/doors",
            },
            {
                id: "admbuild_monitor",
                title: "Live Access Monitor",
                translate: "NAV.MONITOR.TITLE",
                type: "item",
                icon: "web_asset",
                url: "/building/live-access-monitor",
            },
            {
                id: "admbuild_kiosks_list",
                title: "Kiosks",
                translate: "NAV.SMART_KIOSKS.TITLE",
                type: "collapsable",
                icon: "airplay",
                children: [
                    {
                        id: "admbuild_kiosks_list",
                        title: "Kiosks",
                        translate: "NAV.SMART_KIOSKS.TITLE",
                        type: "item",
                        icon: "airplay",
                        url: "/building/kiosks/list",
                    },
                    {
                        id: "admbuild_smart_kiosks",
                        title: "Settings",
                        translate: "NAV.SMART_KIOSKS.SETTINGS",
                        type: "item",
                        icon: "airplay",
                        url: "/building/kiosks/smart",
                    },
                    {
                        id: "kioskSmartReport",
                        title: "Reports",
                        translate: "NAV.SMART_KIOSKS.REPORTS",
                        type: "item",
                        icon: "airplay",
                        url: "/building/kiosks/smart-report",
                    },
                ],
            },
            {
                id: "cac-members",
                title: "Members",
                translate: "NAV.MEMBERS.TITLE",
                type: "item",
                icon: "people_outline",
                url: "/building/members",
                hidden: true,
            },
            {
                id: "cac-cards-cdvi",
                title: "Cards",
                translate: "NAV.CARDS-CDVI.TITLE",
                type: "item",
                icon: "credit_card",
                url: "/building/cards",
                hidden: true,
            },
            {
                id: "cac-panel-doors",
                title: "Doors",
                translate: "NAV.DOORS.TITLE",
                type: "item",
                icon: "meeting_room",
                url: "/building/panel-doors",
                hidden: true,
            },
            {
                id: "cac-areas",
                title: "Areas",
                translate: "NAV.AREAS.TITLE",
                type: "item",
                icon: "device_hub",
                url: "/building/areas",
                hidden: true,
            },
            {
                id: "cac-holidays",
                title: "Holidays",
                translate: "NAV.HOLIDAYS.TITLE",
                type: "item",
                icon: "device_hub",
                url: "/building/holidays",
                hidden: true,
            },
            {
                id: "cac-schedules",
                title: "Schedules",
                translate: "NAV.SCHEDULES.TITLE",
                type: "item",
                icon: "device_hub",
                url: "/building/schedules",
                hidden: true,
            },
            {
                id: "cac-access-level",
                title: "Access Level",
                translate: "NAV.ACCESS_LEVEL.TITLE",
                type: "item",
                icon: "device_hub",
                url: "/building/access-level",
                hidden: true,
            },
            {
                id: "cac-panel-events",
                title: "Events",
                translate: "NAV.EVENTS.TITLE",
                type: "item",
                icon: "code",
                url: "/building/events",
                hidden: true,
            },
            {
                id: "admbuild_reports",
                title: "Reports",
                translate: "NAV.REPORTS.TITLE",
                type: "collapsable",
                icon: "format_indent_increase",
                children: [
                    {
                        id: "admbuild_report_access_mon",
                        title: "Access Monitoring",
                        translate: "NAV.REPORTS.ACCESS_MON.TITLE",
                        type: "item",
                        icon: "exit_to_app",
                        url: "/building/reports/access-monitoring",
                    },
                    {
                        id: "admbuild_tags",
                        title: "Cards/Tags",
                        translate: "NAV.REPORTS.TAGS.TITLE",
                        type: "item",
                        icon: "credit_card",
                        url: "/building/reports/tags",
                    },
                    {
                        id: "report",
                        title: "Site",
                        translate: "NAV.REPORT.TITLE",
                        type: "item",
                        icon: "business",
                        url: "/building/report",
                    },
                    {
                        id: "report",
                        title: "Guest",
                        translate: "NAV.GUEST_REPORT.TITLE",
                        type: "item",
                        icon: "business",
                        url: "/building/reports/guest",
                    },
                    {
                        id: "report",
                        title: "Vehicles",
                        translate: "NAV.VREPORT.TITLE",
                        type: "item",
                        icon: "directions_car",
                        url: "/building/vehicleReport",
                    },
                    {
                        id: "vreportnew",
                        title: "Vehicle Details",
                        translate: "NAV.VREPORTNEW.TITLE",
                        type: "item",
                        icon: "directions_car",
                        url: "/building/vehicleReportNew",
                    },
                    {
                        id: "admbuild_access",
                        title: "Visitor Logs",
                        translate: "NAV.REPORTS.ACCESS.TITLE",
                        type: "item",
                        icon: "open_in_browser",
                        url: "/building/reports/access",
                    },
                    {
                        id: "incident_report",
                        title: "Incident Report",
                        translate: "NAV.REPORTS.INCIDENT_REPORT.TITLE",
                        type: "item",
                        icon: "directions_car",
                        url: "/building/reports/incident-report",
                    },
                    {
                        id: "event_report",
                        title: "Event Report",
                        translate: "NAV.REPORTS.EVENT_REPORT.TITLE",
                        type: "item",
                        icon: "local_bar",
                        url: "/building/reports/event-report",
                    }
                ],
            },
            {
                id: "control_panel",
                title: "Control Panel",
                translate: "NAV.CONTROL_PANEL.TITLE",
                type: "collapsable",
                icon: "format_indent_increase",
                children: [
                    {
                        id: "members",
                        title: "Members",
                        translate: "NAV.MEMBERS.TITLE",
                        type: "item",
                        icon: "people_outline",
                        url: "/building/members",
                        hidden: true,
                    },
                    {
                        id: "cards-cdvi",
                        title: "Cards",
                        translate: "NAV.CARDS-CDVI.TITLE",
                        type: "item",
                        icon: "credit_card",
                        url: "/building/cards",
                    },
                    {
                        id: "panel-doors",
                        title: "Doors",
                        translate: "NAV.DOORS.TITLE",
                        type: "item",
                        icon: "meeting_room",
                        url: "/building/panel-doors",
                    },
                    {
                        id: "areas",
                        title: "Areas",
                        translate: "NAV.AREAS.TITLE",
                        type: "item",
                        icon: "device_hub",
                        url: "/building/areas",
                    },
                    {
                        id: "holidays",
                        title: "Holidays",
                        translate: "NAV.HOLIDAYS.TITLE",
                        type: "item",
                        icon: "device_hub",
                        url: "/building/holidays",
                    },
                    {
                        id: "schedules",
                        title: "Schedules",
                        translate: "NAV.SCHEDULES.TITLE",
                        type: "item",
                        icon: "device_hub",
                        url: "/building/schedules",
                    },
                    {
                        id: "access-level",
                        title: "Access Level",
                        translate: "NAV.ACCESS_LEVEL.TITLE",
                        type: "item",
                        icon: "device_hub",
                        url: "/building/access-level",
                    },
                    // {
                    //     'id'      : 'panels',
                    //     'title'   : 'Panels',
                    //     'translate': 'NAV.PANELS.TITLE',
                    //     'type'    : 'item',
                    //     'icon'    : 'verified_user',
                    //     'url'      : '/building/panels'
                    // },
                    {
                        id: "panel-events",
                        title: "Events",
                        translate: "NAV.EVENTS.TITLE",
                        type: "item",
                        icon: "code",
                        url: "/building/events",
                    },
                ],
            },
            {
                id: "speed_violations",
                title: "Speed Violations",
                translate: "NAV.SPEED_VIOLATIONS.TITLE",
                type: "collapsable",
                icon: "format_indent_increase",
                children: [
                    {
                        id: "speed_violations_opt",
                        title: "In Process",
                        translate: "NAV.SPEED_VIOLATIONS.WORKING_ON",
                        type: "item",
                        // icon: "people_outline",
                        url: "/speed-violation/table",
                    },
                    {
                        id: "reports",
                        title: "Report",
                        translate: "NAV.REPORT.TITLE2",
                        type: "item",
                        // icon: "credit_card",
                        url: "/speed-violation/report",
                    },
                    {
                        id: "speed_violations_settings",
                        title: "Settings",
                        translate: "NAV.SPEED_VIOLATIONS.SETTINGS",
                        type: "item",
                        url: "/speed-violation/settings",
                    },
                    {
                        id: "speed_violations_reports",
                        title: "Reports",
                        translate: "NAV.SPEED_VIOLATIONS.REPORTS",
                        type: "collapsable",
                        children: [
                            {
                                id: "speed_violations_vehicles",
                                title: "Speeding Violation Vehicles",
                                translate: "NAV.SPEED_VIOLATIONS.VEHICLES",
                                type: "item",
                                url: "/speed-violation/vehicles",
                            },
                            {
                                id: "speed_violations_events",
                                title: "Logs",
                                translate: "NAV.SPEED_VIOLATIONS.LOGS",
                                type: "item",
                                url: "/speed-violation/events",
                            },
                        ],
                    }
                ],
            },
            {
                id: "smart_locks",
                title: "Smart Locks",
                translate: "NAV.SMART_LOCKS.TITLE",
                type: "collapsable",
                icon: "format_indent_increase",
                children: [
                    {
                        id: "smart_locks_settings",
                        title: "Settings",
                        translate: "NAV.SMART_LOCKS.SETTINGS",
                        type: "item",
                        url: "/building/smartlocks/settings",
                    },
                    {
                        id: "smart_locks_reports",
                        title: "Reports",
                        translate: "NAV.SMART_LOCKS.REPORTS",
                        type: "item",
                        url: "/building/smartlocks/reports",
                    }
                ],
            }
        ],
    },
    goutruAdmin: {
        id: "goutruAdmin",
        title: "GouTru Admin",
        translate: "NAV.GADMIN",
        type: "group",
        icon: "settings",
        children: [
            {
                id: "buildings",
                title: "Sites",
                translate: "NAV.BUILDINGS.TITLE",
                type: "item",
                icon: "business",
                url: "/buildings",
            },
            {
                id: "gtconnector",
                title: "GouTru Connector",
                translate: "NAV.GTCONNECTOR.TITLE",
                type: "item",
                icon: "device_hub",
                url: "/connectors",
            },
            {
                id: "guest-type",
                title: "Guest types",
                translate: "NAV.GUESTTYPE.TITLE",
                type: "item",
                icon: "people_outline",
                url: "/guest-type",
            },
            {
                id: "users",
                title: "Users",
                translate: "NAV.USERS.TITLE",
                type: "item",
                icon: "people_outline",
                url: "/users",
            },
            {
                id: "dealers",
                title: "Dealers",
                translate: "NAV.DEALERS.TITLE",
                type: "item",
                icon: "people_outline",
                url: "/dealers",
            },
            {
                id: "licenses",
                title: "Licenses",
                translate: "NAV.LICENSES.TITLE",
                type: "item",
                icon: "verified_user",
                url: "/licenses",
            },
            {
                id: "gtadm_kiosks",
                title: "Kiosks",
                translate: "NAV.KIOSKS.TITLE",
                type: "collapsable",
                icon: "airplay",
                children: [
                    {
                        id: "gtadm_kiosks_list",
                        title: "Kiosk List",
                        translate: "NAV.KIOSKS.TITLE",
                        type: "item",
                        icon: "airplay",
                        url: "/kiosks/list",
                    },
                    {
                        id: "gtadm_kiosks_req",
                        title: "Kiosk Activation Requests",
                        translate: "NAV.KIOSKS_APPROVE.TITLE",
                        type: "item",
                        icon: "airplay",
                        url: "/kiosks/approve",
                    },
                ],
            },
        ],
    },
    dealer: {
        id: "dealer",
        title: "Dealer",
        translate: "NAV.DEALER",
        type: "group",
        icon: "settings",
        children: [
            {
                id: "buildings",
                title: "Sites",
                translate: "NAV.BUILDINGS.TITLE",
                type: "item",
                icon: "business",
                url: "/buildings",
            },
            {
                id: "info",
                title: "Site Information",
                translate: "NAV.BUILDINGINFO.TITLE",
                type: "item",
                icon: "business",
                url: "/building/information",
            },
            {
                id: "staff",
                title: "Site Staff",
                translate: "NAV.STAFF.TITLE",
                type: "item",
                icon: "business",
                url: "/building/staff",
            },
            {
                id: "owners",
                title: "Units",
                translate: "NAV.OWNERS.TITLE",
                type: "item",
                icon: "people_outline",
                url: "/building/owners",
            },
            {
                id: "departments",
                title: "Departments",
                translate: "NAV.DEPARTMENTS.TITLE",
                type: "item",
                icon: "people_outline",
                url: "/building/departments",
                hidden: true,
            },
            {
                id: "doors",
                title: "Doors",
                translate: "NAV.DOORS.TITLE",
                type: "item",
                icon: "view_week",
                url: "/building/doors",
            },
            {
                id: "dealer_monitor",
                title: "Live Access Monitor",
                translate: "NAV.MONITOR.TITLE",
                type: "item",
                icon: "web_asset",
                url: "/building/live-access-monitor",
            },
            {
                id: "admbuild_kiosks_list",
                title: "Kiosks",
                translate: "NAV.SMART_KIOSKS.TITLE",
                type: "collapsable",
                icon: "airplay",
                children: [
                    {
                        id: "admbuild_kiosks_list",
                        title: "Kiosks",
                        translate: "NAV.SMART_KIOSKS.TITLE",
                        type: "item",
                        icon: "airplay",
                        url: "/building/kiosks/list",
                    },
                    {
                        id: "admbuild_smart_kiosks",
                        title: "Settings",
                        translate: "NAV.SMART_KIOSKS.SETTINGS",
                        type: "item",
                        icon: "airplay",
                        url: "/building/kiosks/smart",
                    },
                    {
                        id: "kioskSmartReport",
                        title: "Reports",
                        translate: "NAV.SMART_KIOSKS.REPORTS",
                        type: "item",
                        icon: "airplay",
                        url: "/building/kiosks/smart-report",
                    },
                ],
            },
            {
                id: "cac-members",
                title: "Members",
                translate: "NAV.MEMBERS.TITLE",
                type: "item",
                icon: "people_outline",
                url: "/building/members",
                hidden: true,
            },
            {
                id: "cac-cards-cdvi",
                title: "Cards",
                translate: "NAV.CARDS-CDVI.TITLE",
                type: "item",
                icon: "credit_card",
                url: "/building/cards",
                hidden: true,
            },
            {
                id: "cac-panel-doors",
                title: "Doors",
                translate: "NAV.DOORS.TITLE",
                type: "item",
                icon: "meeting_room",
                url: "/building/panel-doors",
                hidden: true,
            },
            {
                id: "cac-areas",
                title: "Areas",
                translate: "NAV.AREAS.TITLE",
                type: "item",
                icon: "device_hub",
                url: "/building/areas",
                hidden: true,
            },
            {
                id: "cac-holidays",
                title: "Holidays",
                translate: "NAV.HOLIDAYS.TITLE",
                type: "item",
                icon: "device_hub",
                url: "/building/holidays",
                hidden: true,
            },
            {
                id: "cac-schedules",
                title: "Schedules",
                translate: "NAV.SCHEDULES.TITLE",
                type: "item",
                icon: "device_hub",
                url: "/building/schedules",
                hidden: true,
            },
            {
                id: "cac-access-level",
                title: "Access Level",
                translate: "NAV.ACCESS_LEVEL.TITLE",
                type: "item",
                icon: "device_hub",
                url: "/building/access-level",
                hidden: true,
            },
            {
                id: "cac-panel-events",
                title: "Events",
                translate: "NAV.EVENTS.TITLE",
                type: "item",
                icon: "code",
                url: "/building/events",
                hidden: true,
            },
            {
                id: "dealerbuild_reports",
                title: "Reports",
                translate: "NAV.REPORTS.TITLE",
                type: "collapsable",
                icon: "format_indent_increase",
                children: [
                    {
                        id: "report",
                        title: "Site",
                        translate: "NAV.REPORT.TITLE",
                        type: "item",
                        icon: "business",
                        url: "/building/report",
                    },
                    {
                        id: "report",
                        title: "Vehicles",
                        translate: "NAV.VREPORT.TITLE",
                        type: "item",
                        icon: "directions_car",
                        url: "/building/vehicleReport",
                    },
                    {
                        id: "admbuild_access",
                        title: "Visitor Logs",
                        translate: "NAV.REPORTS.ACCESS.TITLE",
                        type: "item",
                        icon: "open_in_browser",
                        url: "/building/reports/access",
                    },
                    {
                        id: "admbuild_tags",
                        title: "Cards/Tags",
                        translate: "NAV.REPORTS.TAGS.TITLE",
                        type: "item",
                        icon: "credit_card",
                        url: "/building/reports/tags",
                    },
                ],
            },
            {
                id: "control_panel",
                title: "Control Panel",
                translate: "NAV.CONTROL_PANEL.TITLE",
                type: "collapsable",
                icon: "format_indent_increase",
                children: [
                    {
                        id: "members",
                        title: "Members",
                        translate: "NAV.MEMBERS.TITLE",
                        type: "item",
                        icon: "people_outline",
                        url: "/building/members",
                    },
                    {
                        id: "cards-cdvi",
                        title: "Cards",
                        translate: "NAV.CARDS-CDVI.TITLE",
                        type: "item",
                        icon: "credit_card",
                        url: "/building/cards",
                    },
                    {
                        id: "panel-doors",
                        title: "Doors",
                        translate: "NAV.DOORS.TITLE",
                        type: "item",
                        icon: "meeting_room",
                        url: "/building/panel-doors",
                    },
                    {
                        id: "areas",
                        title: "Areas",
                        translate: "NAV.AREAS.TITLE",
                        type: "item",
                        icon: "device_hub",
                        url: "/building/areas",
                    },
                    {
                        id: "holidays",
                        title: "Holidays",
                        translate: "NAV.HOLIDAYS.TITLE",
                        type: "item",
                        icon: "device_hub",
                        url: "/building/holidays",
                    },
                    {
                        id: "schedules",
                        title: "Schedules",
                        translate: "NAV.SCHEDULES.TITLE",
                        type: "item",
                        icon: "device_hub",
                        url: "/building/schedules",
                    },
                    {
                        id: "access-level",
                        title: "Access Level",
                        translate: "NAV.ACCESS_LEVEL.TITLE",
                        type: "item",
                        icon: "device_hub",
                        url: "/building/access-level",
                    },
                    {
                        id: "panel-events",
                        title: "Events",
                        translate: "NAV.EVENTS.TITLE",
                        type: "item",
                        icon: "code",
                        url: "/building/events",
                    },
                ],
            },
        ],
    },
    dealertech: {
        id: "dealertech",
        title: "Dealer Tech",
        translate: "NAV.DEALERTECH",
        type: "group",
        icon: "settings",
        children: [
            {
                id: "buildings",
                title: "Sites",
                translate: "NAV.BUILDINGS.TITLE",
                type: "item",
                icon: "business",
                url: "/buildings",
            },
            {
                id: "info",
                title: "Site Information",
                translate: "NAV.BUILDINGINFO.TITLE",
                type: "item",
                icon: "business",
                url: "/building/information",
            },
            {
                id: "staff",
                title: "Site Staff",
                translate: "NAV.STAFF.TITLE",
                type: "item",
                icon: "business",
                url: "/building/staff",
            },
            {
                id: "owners",
                title: "Units",
                translate: "NAV.OWNERS.TITLE",
                type: "item",
                icon: "people_outline",
                url: "/building/owners",
            },
            {
                id: "departments",
                title: "Departments",
                translate: "NAV.DEPARTMENTS.TITLE",
                type: "item",
                icon: "people_outline",
                url: "/building/departments",
                hidden: true,
            },
            {
                id: "doors",
                title: "Doors",
                translate: "NAV.DOORS.TITLE",
                type: "item",
                icon: "view_week",
                url: "/building/doors",
            },
            {
                id: "dealertech_monitor",
                title: "Live Access Monitor",
                translate: "NAV.MONITOR.TITLE",
                type: "item",
                icon: "web_asset",
                url: "/building/live-access-monitor",
            },
            {
                id: "admbuild_kiosks_list",
                title: "Kiosks",
                translate: "NAV.SMART_KIOSKS.TITLE",
                type: "collapsable",
                icon: "airplay",
                children: [
                    {
                        id: "admbuild_kiosks_list",
                        title: "Kiosks",
                        translate: "NAV.SMART_KIOSKS.TITLE",
                        type: "item",
                        icon: "airplay",
                        url: "/building/kiosks/list",
                    },
                    {
                        id: "admbuild_smart_kiosks",
                        title: "Settings",
                        translate: "NAV.SMART_KIOSKS.SETTINGS",
                        type: "item",
                        icon: "airplay",
                        url: "/building/kiosks/smart",
                    },
                    {
                        id: "kioskSmartReport",
                        title: "Reports",
                        translate: "NAV.SMART_KIOSKS.REPORTS",
                        type: "item",
                        icon: "airplay",
                        url: "/building/kiosks/smart-report",
                    },
                ],
            },
            {
                id: "dealertechbuild_reports",
                title: "Reports",
                translate: "NAV.REPORTS.TITLE",
                type: "collapsable",
                icon: "format_indent_increase",
                children: [
                    {
                        id: "admbuild_report_access_mon",
                        title: "Access Monitoring",
                        translate: "NAV.REPORTS.ACCESS_MON.TITLE",
                        type: "item",
                        icon: "exit_to_app",
                        url: "/building/reports/access-monitoring",
                    },
                    {
                        id: "admbuild_tags",
                        title: "Cards/Tags",
                        translate: "NAV.REPORTS.TAGS.TITLE",
                        type: "item",
                        icon: "credit_card",
                        url: "/building/reports/tags",
                    },
                    {
                        id: "report",
                        title: "Site",
                        translate: "NAV.REPORT.TITLE",
                        type: "item",
                        icon: "business",
                        url: "/building/report",
                    },
                    {
                        id: "report",
                        title: "Vehicles",
                        translate: "NAV.VREPORT.TITLE",
                        type: "item",
                        icon: "directions_car",
                        url: "/building/vehicleReport",
                    },
                    {
                        id: "admbuild_access",
                        title: "Visitor Logs",
                        translate: "NAV.REPORTS.ACCESS.TITLE",
                        type: "item",
                        icon: "open_in_browser",
                        url: "/building/reports/access",
                    },
                ],
            },
            {
                id: "control_panel",
                title: "Control Panel",
                translate: "NAV.CONTROL_PANEL.TITLE",
                type: "collapsable",
                icon: "format_indent_increase",
                children: [
                    {
                        id: "members",
                        title: "Members",
                        translate: "NAV.MEMBERS.TITLE",
                        type: "item",
                        icon: "people_outline",
                        url: "/building/members",
                    },
                    {
                        id: "cards-cdvi",
                        title: "Cards",
                        translate: "NAV.CARDS-CDVI.TITLE",
                        type: "item",
                        icon: "credit_card",
                        url: "/building/cards",
                    },
                    {
                        id: "panel-doors",
                        title: "Doors",
                        translate: "NAV.DOORS.TITLE",
                        type: "item",
                        icon: "meeting_room",
                        url: "/building/panel-doors",
                    },
                    {
                        id: "areas",
                        title: "Areas",
                        translate: "NAV.AREAS.TITLE",
                        type: "item",
                        icon: "device_hub",
                        url: "/building/areas",
                    },
                    {
                        id: "holidays",
                        title: "Holidays",
                        translate: "NAV.HOLIDAYS.TITLE",
                        type: "item",
                        icon: "device_hub",
                        url: "/building/holidays",
                    },
                    {
                        id: "schedules",
                        title: "Schedules",
                        translate: "NAV.SCHEDULES.TITLE",
                        type: "item",
                        icon: "device_hub",
                        url: "/building/schedules",
                    },
                    {
                        id: "access-level",
                        title: "Access Level",
                        translate: "NAV.ACCESS_LEVEL.TITLE",
                        type: "item",
                        icon: "device_hub",
                        url: "/building/access-level",
                    },
                    {
                        id: "panel-events",
                        title: "Events",
                        translate: "NAV.EVENTS.TITLE",
                        type: "item",
                        icon: "code",
                        url: "/building/events",
                    },
                ],
            },
        ],
    },
};